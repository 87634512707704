<template>
  <div class="project-sale-status" :class="getStatusClass()">
    {{ getStatus() }}
  </div>
</template>

<script>
import { projectSalesPhase as STATUS } from "@/enums";

export default {
  components: {},
  mixins: [],
  props: {
    status: {
      type: String,
      required: true,
      validator: (val) => Object.values(STATUS).indexOf(val) !== -1
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    getStatusClass() {
      return this.status.toLowerCase().split(" ").join("-");
    },
    getStatus() {
      if (this.status === STATUS.VACANT_POSSESSION_NOTICE) {
        return "VP Notice";
      } else {
        return this.status;
      }
    }
  }
};
</script>

<style lang="scss">
.project-sale-status {
  border-radius: 50px;
  padding: 6px 18px;
  text-align: center;
  min-width: 140px;
  width: fit-content;
  background: #ddd;
  color: #555;

  @mixin projectStatus($class, $bg-color, $color) {
    &.#{$class} {
      background: $bg-color;
      color: $color;
    }
  }

  @include projectStatus("reserve", $color-accent, white);
  @include projectStatus("waiting-list", $color-accent, white);
  @include projectStatus("booking", $color-main, white);
  @include projectStatus("mortgage", $color-warn, white);
  @include projectStatus("lawyer", $color-accent2, white);
  @include projectStatus("vacant-possession-notice", $color-secondary, white);
  @include projectStatus("completed", $color-success, white);
}
</style>
